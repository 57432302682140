import { useState, useEffect } from 'react';
import axios from 'axios';
import Api from './Api.js'

// Base URL of your Laravel API
// const BASE_URL = process.env.REACT_APP_API_URL;

// const api = axios.create({
//   baseURL: process.env.REACT_APP_API_URL,
//   headers: {
//     'X-API-KEY': process.env.REACT_APP_API_SECRET_KEY,
//   },
// });
export const useApi = (endpoint , options = {}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const fetchData = async () => {
      try {
        const response = await Api.get(`/${endpoint}`, { ...options, cancelToken: source.token });
        setData(response.data);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled', error.message);
        } else {
          setError(error.response ? error.response.data : error.message);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    // return () => source.cancel('Operation canceled by the user.');
  }, [endpoint, JSON.stringify(options)]);

  return { data, loading, error };
};
