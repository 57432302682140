import React from 'react'
import './Project.css'
import {ButtonSplashScreen} from '../../components/index'
import useTrackInteraction from '../../useTrackInteraction'


const Project = (props) => {

  const [trackInteraction] = useTrackInteraction(); 
  const handleButtonClick = (action, details) => {
    trackInteraction(action, details);
  };



  return (
    <div className="col-md-6 mb-4 project">
        
            <div className="card">
              <div className='img-continer'>

              </div>
            {
            props.img ? (
            <img src={props.img} className="card-img-top" alt={props.name} />
            ) : null
            }
            <div className="card-body">
                <h5 className="card-title">{props.name}</h5>

                <p className="card-text" dangerouslySetInnerHTML={{ __html: props.des }}></p>
                <ButtonSplashScreen onClick={() => handleButtonClick('view_my_project',`${props.name}`)} name='More Details' url={props.url} newTab={props.url =='#'?false:true}/>
            </div>

            </div>
    </div>
  )
}

export default Project