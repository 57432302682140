import { useState } from 'react';
import Api from './Api'

const useTrackInteraction = () => {

          const [isloading, setisloading] = useState(false);
          const [error, Seterror] = useState(null);

          const TrackInteraction = async (action, details) => {
                    setisloading(true);
                    Seterror(null);

                    try {
                              const response = await Api.post('/interactions', {action, details});

                              if (!response.ok) {
                                        throw new Error('Failed to track interaction');
                                      }
                    } catch(err){
                              Seterror(err.message);
                    } finally {
                              setisloading(false);
                            }
          };

          return [TrackInteraction, isloading, error];

};


export default useTrackInteraction;