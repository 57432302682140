import React from 'react'
import {FaWhatsapp ,FaFacebook ,FaGithub} from "react-icons/fa"
import {MdAlternateEmail} from "react-icons/md"
import {GrLinkedin} from 'react-icons/gr'
import {SiTryhackme} from 'react-icons/si'
import './SocialMeadia.css'
import { SocialMeadiaData } from '../../Portfolio'
import { useApi } from '../../useApi';
import useTrackInteraction from '../../useTrackInteraction'


const SocialMeadia = (props) => {

  const iconsComponent = {
    facebook: <FaFacebook />,
    linkedin: <GrLinkedin />,
    github: <FaGithub />,
    whatsapp: <FaWhatsapp />,
    email: <MdAlternateEmail />,
    tryhackme: <SiTryhackme />
  };

  const { data, loading, error } = useApi('data');
  const [trackInteraction] = useTrackInteraction(); 

  let socialMediaData = [];

  if (loading) return <p>Loading...</p>;
  if (error) socialMediaData = SocialMeadiaData;

  const content = data && data.length > 0 ? data[0] : null;

  if(content){
    socialMediaData = content.social_media;
  }

  const handleButtonClick = (action, details) => {
    trackInteraction(action, details);
  };


  return (
    <div className="social-icons">
      {
        socialMediaData.map((item, index) => {
          const { name, url } = item;
          const IconComponent = iconsComponent[name];

          if (!IconComponent) return null;

          return (
            <a href={url} onClick={() => handleButtonClick('social_media',name) } className={name} target={props.newTab && "_blank"} key={index}>
            <i>{IconComponent}</i>
            </a>
          );

        })
      }
      {/* <a href={SocialMeadiaData.facebook} target={props.newTab && "_blank"}  className='facebook'><i><FaFacebook/></i></a>
      <a href={SocialMeadiaData.linkedin} target={props.newTab && "_blank"}  className='linkedin'><i><GrLinkedin/></i></a>
      <a href={SocialMeadiaData.github} target={props.newTab && "_blank"} className='github'><i><FaGithub/></i></a>
      <a href={SocialMeadiaData.whatsapp} target={props.newTab && "_blank"} className='whatsapp'><i><FaWhatsapp/></i></a>
      <a href={SocialMeadiaData.email} target={props.newTab && "_blank"} className='email'><i><MdAlternateEmail/></i></a>
      <a href={SocialMeadiaData.tryhackme} target={props.newTab && "_blank"} className='email'><i><SiTryhackme/></i></a> */}

      
    </div>
  )
}

export default SocialMeadia