import React from 'react'
import './ProjectsOnline.css'
import {OlineProjects} from '../../Portfolio'
import { Project } from '../../components/index'
import { useApi } from '../../useApi'



const ProjectsOnline = () => {

  const { data, loading, error } = useApi('data');
  let onlineProject = [];

    if (loading) return <p>Loading...</p>;
    if (error)  onlineProject = OlineProjects.projects;

    const content = data && data.length > 0 ? data[0] : null;
    if(content){
       onlineProject = content.products_online;
    }

    


  return (
    <section className="main bg-dark" id="projects">
    <div className="container">
        <h2 className="title mb-4">Projects online</h2>
        <div className="row">
            {
                onlineProject.map((element,i)=>(
                    <Project
                    key={i}
                    name={element.name}
                    des={element.des}
                    img={element.img}
                    url={element.url}
                    />
                ))
            }
        </div>
    </div>
    </section>
  )
}

export default ProjectsOnline