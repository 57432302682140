import axios from 'axios';


const generateSignature = async (apiKey, secret) => {
  const encoder = new TextEncoder();
  const keyData = encoder.encode(secret);
  const key = await crypto.subtle.importKey('raw', keyData, { name: 'HMAC', hash: 'SHA-256' }, true, ['sign']);
  
  const timestamp = new Date().toISOString();
  const message = `${apiKey}:${timestamp}`;
  const messageBytes = encoder.encode(message);
  
  const signature = await crypto.subtle.sign('HMAC', key, messageBytes);
  const hashArray = Array.from(new Uint8Array(signature)); // Convert buffer to byte array
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // Convert bytes to hex string
  
  return { hash: hashHex, timestamp };
};


// Create an Axios instance with common configuration
const encodedAPIKey = btoa(process.env.REACT_APP_API_SECRET_KEY);
const secret = process.env.REACT_APP_API_SECRET;

const { hash, timestamp } = await generateSignature(encodedAPIKey, secret);


const Api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'X-API-KEY': encodedAPIKey,
    'X-Signature': hash,
    'X-Timestamp': timestamp,
  },
});

export default Api;
