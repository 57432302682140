import React from 'react'
import "./Projects.css"
import {ButtonSplashScreen, Project} from '../../components/index'
import { ProjectsData } from '../../Portfolio'
import { useApi } from '../../useApi'
import useTrackInteraction from '../../useTrackInteraction'


const Projects = () => {

    const { data, loading, error } = useApi('data');
    const [trackInteraction] = useTrackInteraction(); 

    let OpenSource = [];

    if (loading) return <p>Loading...</p>;
    if (error) OpenSource = ProjectsData.projects;

    const content = data && data.length > 0 ? data[0] : null;

    if(content){
        OpenSource = content.products_open_source;
    }

    var githubAccount = '';
    if(content){
         githubAccount = content.social_media.find(item => item.name === 'github');
    }else{
         githubAccount = null;
    }


    const handleButtonClick = (action, details) => {
        trackInteraction(action, details);
      };


  return (
        <section className="main bg-dark" id="onpensource">
    <div className="container">
        <h2 className="title mb-4">Projects Open Source</h2>
        <div className="row">
            {
                OpenSource.map((element,i)=>(
                    <Project
                    key={i}
                    name={element.name}
                    des={element.des}
                    img={element.img}
                    url={element.url}
                    />
                ))
            }
            {githubAccount && 
                    <div className='d-flex justify-content-center' id='btn-project'>
                        <ButtonSplashScreen onClick={() => handleButtonClick('social_media','github')} name='View More Projects' url={githubAccount.url} newTab={true}/>
                    </div>
            }
          
        </div>
    </div>
    </section>

  )
}

export default Projects