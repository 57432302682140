import React from 'react'
import "./TextAnimated.css"
import Typewriter from 'typewriter-effect'
import { TextAnimated as TextAn } from '../../Portfolio';
import { useApi } from '../../useApi';


const TextAnimated = () => {

  const { data, loading, error } = useApi('data');
  let textAnimatedData = [];

  if (loading) return <p>Loading...</p>;
  if (error) textAnimatedData = TextAn;

  const content = data && data.length > 0 ? data[0] : null;
  if(content){
    textAnimatedData = content.hero_section_text_animated;
    try {
      textAnimatedData = JSON.parse(textAnimatedData);
    } catch (e) {
      textAnimatedData = textAnimatedData.split(',');
    }
  }

     

  // console.log(textAnimatedData);
 

  return (
    <span className="typed">
        <Typewriter
        options={{
            autoStart:true,
            loop:true,
            delay:60,
            strings:textAnimatedData
        }}
        />
    </span>
  )
}

export default TextAnimated