import React, { useState } from 'react';
import './ContactMe.css'
import {ContactMeData ,isHireable } from '../../Portfolio'
import { Bubbles, SocialMeadia } from '../../components'
import ContactModal from '../../components/ContactForm/ContactModal'
import { Button } from 'react-bootstrap';



const ContactMe = () => {

    const [showModal, setShowModal] = useState(false);

    const handleOpen = () => setShowModal(true);
    const handleClose = () => setShowModal(false);


  return (
    <section className="main" id='contact'>
      <Bubbles/>
        
        <div className="container">
            <div className="row">
            <div className="col-md-8 mx-auto text-center">
            
                <h2 className="title">Get in Touch!</h2>
                <hr className="my-4"/>
                <p className="mb-5">{ContactMeData.title}</p>
                <div className='social'>
                <SocialMeadia newTab={true}/>
                </div>
                
                <p className="mb-5">Available for freelance work:
                <span className='hireable'>{isHireable ? ' Yes' : ' NO'}</span>
                </p>
                <Button className="button-contact" variant="primary" onClick={handleOpen}>
                    Send message
                </Button>

                <p className="mb-5 font-weight-bold text-danger">{ContactMeData.msg}</p>
            </div>
            </div>
        </div>
        <ContactModal showModal={showModal} handleClose={handleClose}/>
    </section>
  )
}

export default ContactMe