import React, { useState } from 'react';
import './ContactForm.css'
// import axios from 'axios'; 
import { Button, Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import Api  from '../../Api'
import ReCAPTCHA from "react-google-recaptcha";



const ContactForm = ({ handleClose }) => {


            const [recaptchaToken, setRecaptchaToken] = useState('');

            const [formData, setFormData] = useState({
                name: '',
                email: '',
                subject: '',
                message: '',
            });

            const [isSubmitting, setIsSubmitting] = useState(false);

            const isFormValid = () => {
                return formData.name && formData.email && formData.subject && formData.message && recaptchaToken;
            };

          const handleChange = (e) => {
                    const {name ,value} = e.target;
                    setFormData(prevState => ({
                              ...prevState,
                              [name] :value,
                    }));
          };

          const handleRecaptcha = (token) => {
                setRecaptchaToken(token);
          };

          const handleSubmit = async (e) => {
                    e.preventDefault();

                    if (!isFormValid()) {
                        Swal.fire({
                            icon: 'warning',
                            title: 'Oops...',
                            text: 'Please complete all the fields and verify you are not a robot.',
                        });
                        return;
                    }
                    setIsSubmitting(true);
                    try {
                        const response = await Api.post(`/contact-us-send`, { ...formData, recaptchaToken });
                    //     console.log(response.data);
                        Swal.fire({
                              icon: 'success',
                              title: 'Success',
                              text: response.data.message,
                          });
                        handleClose();
                    } catch (error) {
                        let message = 'Failed to submit form. Please try again.'; // Default error message
                        if (error.response) {
                          // The request was made and the server responded with a status code
                          // that falls out of the range of 2xx
                          console.error("Error data:", error.response.data);
                          console.error("Error status:", error.response.status);
                      
                          // Handle common HTTP errors
                          if (error.response.status === 422) {
                            // Extract validation errors or use a fallback message
                            const errors = error.response.data.errors || {};
                            message = Object.values(errors).map(msgArray => msgArray.join(', ')).join('; ') || 'Validation error. Please check your input.';
                          } else if (error.response.status === 500) {
                            message = 'Server error. Please try again later.';
                          }
                          // Additional status codes can be handled here
                        } else if (error.request) {
                          // The request was made but no response was received
                          console.error("No response:", error.request);
                          message = 'No response from the server. Please check your connection.';
                        } else {
                          // Something happened in setting up the request that triggered an Error
                          console.error("Error message:", error.message);
                        }
                      
                        // Display error message to the user
                        Swal.fire({
                          icon: 'error',
                          title: 'Oops...',
                          text: message,
                        });
                      }
          };




          
          return (
              <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formBasicName">
                      <Form.Label>Name</Form.Label>
                      <Form.Control name="name" type="text" placeholder="Enter Name" value={formData.name} onChange={handleChange}  />
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control name="email" type="email" placeholder="Enter email" value={formData.email} onChange={handleChange}  />
                      <Form.Text className="text-muted">
                          We'll never share your email with anyone else.
                      </Form.Text>
                  </Form.Group>
                  <Form.Group controlId="subject">
                      <Form.Label>subject</Form.Label>
                      <Form.Control name="subject" type="text" placeholder="Enter subject" value={formData.subject} onChange={handleChange}  />
                  </Form.Group>
                 
      
                  <Form.Group controlId="formBasicMessage">
                      <Form.Label>Message</Form.Label>
                      <Form.Control name="message" as="textarea" rows={3} placeholder="Your message" value={formData.message} onChange={handleChange} />
                  </Form.Group>
                  <ReCAPTCHA
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                        onChange={handleRecaptcha}
                    />
                  <Button className='mt-2' variant="primary" type="submit">
                      Send Message
                  </Button>
              </Form>
          );
      };
      
      export default ContactForm;