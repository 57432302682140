// ContactModal.js
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import ContactForm from './ContactForm'




const ContactModal = ({ showModal, handleClose }) => {

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Contact Form</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ContactForm handleClose={handleClose} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ContactModal;
