import React from 'react'
import "./SplashScreen.css"
// import iconHello from "../../assets/images/1F44B.svg"
import {greeting} from "../../Portfolio"
import {ButtonSplashScreen, SocialMeadia, TextAnimated} from '../../components/index'
import { useApi } from '../../useApi'
import useTrackInteraction from '../../useTrackInteraction'


const SplashScreen = () => {

  const { data, loading, error } = useApi('data');

  const [trackInteraction] = useTrackInteraction(); 

  const handleButtonClick = (action, details) => {
    trackInteraction(action, details);
  };

  let texttitle = '';
  let htmlContent = '';
  let subtitle = '';
  let resume = null;


  if (loading) return <p>Loading...</p>;
  if (error){
     texttitle = greeting.title;
     htmlContent ={ __html: texttitle };
     subtitle = greeting.subtitle;

    // let resume =  ;

  }

  const content = data && data.length > 0 ? data[0] : null;

  if(content){
     texttitle = content.hero_title_section;
     htmlContent = { __html: texttitle };
     subtitle = content.hero_sub_title_section ;
     resume = content.resume_link;
  }
 

   

  // console.log(resume);




  return (
    <section className="splashscreen" id='splashscreen' >
            
                <div className='row'>
                    <div className='col-md splashcontent'>
                            <h2>
                            <div dangerouslySetInnerHTML={htmlContent}></div>
                            <TextAnimated/>
                            </h2>
                            <h3>
                            {subtitle}
                            </h3>

                            <SocialMeadia
                              newTab={true}
                            />
                        {
                          greeting.buttonNormal.map((element,i)=> (
                            <ButtonSplashScreen onClick={() => handleButtonClick('view_my_work',`${element.name}`)} url={element.url} name={element.name} newTab={false} key={i}/>
                          ))
                        }
                        {resume && <ButtonSplashScreen onClick={() => handleButtonClick('resume_click', 'Clicked on Resume')} url={resume.url} name={resume.name} newTab={true} />}
                        
                    </div>
                </div>
                
            
    </section>
  )
}
export default SplashScreen